<!--
 * @Descripttion: 门店列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-08-02 15:15:31
 * @LastEditors: dxl 1668645906@qq.com
 * @LastEditTime: 2023-08-25 14:09:24
-->
<template>
    <div class="shopList">
        <div class="sl-title">
            <commonSelect
                @changeDate="changeDate"
                @clickTagDate="clickTagDate"
            ></commonSelect>
            <el-button :loading="btnLoading" v-if="userInfo.kf_level == 1"
                @click="submitExport" type="success">导出
            </el-button>
            <el-button :loading="btnLoading" v-if="userInfo.kf_level == 1"
                @click="submitExport2" type="success">导出到期时间
            </el-button>
        </div>
        <div class="box-wrap">
            <div class="screen-wrap">
                <div class="sw-wrap">
                    <div class="sw-item">
                        <div class="select-wrap" v-if="userInfo.kf_level == 1">
                            <el-select @change="parChange" v-model="parId" placeholder="请选择是否参与统计筛选">
                                <el-option
                                    v-for="item in parOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="select-wrap">
                            <el-select clearable filterable @change="selectChangeEp" v-model="ep_id" placeholder="请选择品牌">
                                <el-option
                                    v-for="item in epOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="select-wrap" v-if="ep_id!=''">
                            <el-select clearable filterable @change="selectAreaChange" v-model="areaUserId" placeholder="请选择区域经理筛选">
                                <el-option
                                    v-for="item in areaOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="select-wrap">
                            <el-select clearable filterable @change="selectChangeAuth" v-model="authStatus" placeholder="请选择审核状态">
                                <el-option
                                    v-for="item in authOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="select-wrap">
                            <el-select clearable filterable @change="selectChange" v-model="shopUserId" placeholder="请选择负责人筛选">
                                <el-option
                                    v-for="item in userOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="search-wrap">
                            <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                            <el-button @click="clickSearch" type="primary">搜索</el-button>
                        </div>
                    </div>
                    <div class="tag-wrap">
                        <div class="tw-item">
                            <div class="item" style="border: 1px solid #ddd;"></div>正常
                        </div>
                        <div class="tw-item">
                            <div class="item" style="background: #e6a23c"></div>即将过期
                        </div>
                        <div class="tw-item">
                            <div class="item" style="background: #f56c6c"></div>已过期
                        </div>
                        <div class="tw-item">
                            <div class="item" style="background: #dddddd"></div>被停用
                        </div>
                    </div>
                </div>
            </div>
            <el-table :data="tableData" border class="table-wrap"
                v-loading="loading" :row-class-name="tableRowClassName" height="580">
                <el-table-column fixed="left" align="center" prop="id" label="ID" width="60"></el-table-column>
                <el-table-column align="center" class-name="vm-wrap" label="门店名称" width="140" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                    <div class="tag" v-if="scope.row.shop_is_new == 1">
                        <div class="new">NEW</div>
                    </div>
                    <div>{{scope.row.name}}</div>
                </template>
                </el-table-column>
                <el-table-column align="center" prop="shop_owner.username" label="店长" width="80" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="shop_owner.mobile" label="联系电话" width="120"></el-table-column>
                <el-table-column align="center" prop="enterprise_shop_rel.remark_shop_name" label="备注名称" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="enterprise_shop_rel.remark_shop_address" label="备注地区" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="user_count" width="90">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据子账号排序" placement="top" width="50">
                            <div class="sort-wrap" @click="changeShow('user_count')">
                                <span>子账号</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="customer_count" width="90">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据客户排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('customer_count')">
                                <span>客户</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="customer_record_count" width="100">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据成交单量排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('customer_record_count')">
                                <span>成交单量</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="business_gmv_sum" width="100">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据成交金额排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('business_gmv_sum')">
                                <span>成交金额</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="browsing_records_count" width="90">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据曝光排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('browsing_records_count')">
                                <span>曝光</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="comment_count" width="100">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据口碑积攒排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('comment_count')">
                                <span>口碑积攒</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="vr_imgs_count" width="100">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="跟据全景设计排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('vr_imgs_count')">
                                <span>全景设计</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="quotation_count" width="100">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="跟据报价数排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('quotation_count')">
                                <span>报价数</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="quotation_share_count" width="120">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="跟据报价分享数排序" placement="top">
                            <div class="sort-wrap sort-item" @click="changeShow('quotation_share_count')">
                                <span>报价分享数</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center"  label="门店认证" width="110" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div v-if="scope.row.shop_apply != null">
                            <div style="color: #999999;" v-if="scope.row.shop_apply.business_license_img_state == 0">未提交</div>
                            <div style="color: #f5bd00;" v-if="scope.row.shop_apply.business_license_img_state == 1">待审核</div>
                            <div style="color: #00d90d;" v-if="scope.row.shop_apply.business_license_img_state == 2">审核通过</div>
                            <div style="color: #ff0100;" v-if="scope.row.shop_apply.business_license_img_state == 3">审核不通过</div>
                        </div>
                        <div v-else style="color: #999999;">未提交</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="支付认证" width="110" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div v-if="scope.row.shop_apply != null">
                            <div style="color: #999999;" v-if="scope.row.shop_apply.state == 0">未提交</div>
                            <div style="color: #f5bd00;" v-if="scope.row.shop_apply.state == 1">待审核</div>
                            <div style="color: #00d90d;" v-if="scope.row.shop_apply.state == 2">审核通过</div>
                            <div style="color: #ff0100;" v-if="scope.row.shop_apply.state == 3">认证不通过</div>
                        </div>
                        <div v-else style="color: #999999;">未提交</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="last_login_time" label="最后登录时间" width="110"></el-table-column>
                <el-table-column align="center" prop="sale_name" label="区域经理" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="system_admin.nickname" label="客服负责人" width="100"></el-table-column>
                <el-table-column align="center" prop="adminuser_allocate_time" label="分配时间" width="130" :show-overflow-tooltip="true">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="跟据分配时间排序" placement="top">
                            <div class="sort-wrap" @click="changeShow('adminuser_allocate_time')">
                                <span>分配时间</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="create_timex" label="创建时间" width="130" :show-overflow-tooltip="true">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="跟据创建时间排序" placement="top">
                            <div class="sort-wrap" @click="changeShow('create_timex')">
                                <span>创建时间</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" fixed="right" label="操作" :show-overflow-tooltip="true" width="200">
                    <template slot-scope="scope">
                        <div class="operation-wrap">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <i @click="clickInfo(scope.row)" class="iconfont el-icon-document"></i>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="同步产品" placement="top">
                                <i @click="clickSyn(scope.row)" class="iconfont el-icon-sold-out"></i>
                            </el-tooltip>
                            <el-tooltip v-if="scope.row.is_statistic == 1" class="item" effect="dark" content="分配" placement="top">
                                <i v-if="userInfo.kf_level == 1" @click="clickAllocation(scope.row)" class="iconfont el-icon-sort"></i>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="续费" placement="top">
                                <i v-if="userInfo.kf_level == 1" 
                                    @click="clickRenew(scope.row)" class="iconfont el-icon-postcard">
                                </i>
                            </el-tooltip>
                            <el-tooltip v-if="scope.row.is_statistic == 1 && userInfo.kf_level == 1"  class="item" effect="dark" content="不参与统计" placement="top">
                                <i
                                    @click="clickPart(scope.row)" class="iconfont el-icon-sort-down">
                                </i>
                            </el-tooltip>
                            <el-tooltip v-if="scope.row.is_statistic == 0 && userInfo.kf_level == 1"  class="item" effect="dark" content="参与统计" placement="top">
                                <i
                                    @click="clickPart(scope.row)" class="iconfont el-icon-sort-up">
                                </i>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <i v-if="userInfo.kf_level == 1" 
                                    @click="clickDel(scope.row)" class="iconfont el-icon-delete">
                                </i>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="50"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 分配 -->
        <el-dialog :visible.sync="isAllocation" 
            :before-close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div class="title">{{title}}</div>
            <div class="content">
                <div class="tag">分配至：</div>
                <el-select clearable v-model="userId" placeholder="请选择负责人">
                    <el-option
                        v-for="item in userOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="btn-wrap">
                <el-button :loading="btnLoading" @click="submitAllocation" type="primary">确定</el-button>
            </div>
        </el-dialog>
        <!-- 续费 -->
        <el-dialog
        :visible.sync="isshowRenew" 
        :before-close="handleCloseRenew"
        custom-class="dialog-wrap"
        :close-on-click-modal="false">
            <div class="title">{{renewName}}-续费</div>
            <div class="content renew">
                <div class="item">
                    <div class="tit">续费时间</div>
                    <el-date-picker
                        v-model="renewDate"
                        type="date"
                        :picker-options="option"
                        placeholder="请选择日期">
                    </el-date-picker>
                </div>
                <div class="item">
                    <div class="tit">增加支付订单</div>
                    <el-switch v-model="is_need_pay"
                        active-color="#13ce66" inactive-color="#eee">
                    </el-switch>
                </div>
                <div class="item" v-if="is_need_pay">
                    <div class="tit">支付金额</div>
                    <el-input v-model="pay_real_price" placeholder="请输入订单金额"></el-input>
                </div>
                <div class="item" v-if="is_need_pay">
                    <div class="tit">支付方式</div>
                    <el-select clearable v-model="pay_way" placeholder="请选择支付方式">
                        <el-option
                            v-for="item in wayOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="btn-wrap">
                <el-button :loading="btnLoading" @click="submitRenew" type="primary">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import common from "@/utils/common.js"
import axios from "axios";
import commonSelect from "@/components/commonSelect.vue"
import {timestampToTime} from "@/utils/timeCycle";
export default {
    components: {
      commonSelect
    },
    data () {
        return {
            user: {},
            userInfo: {},
            info: {},
            tableData: [],
            loading: false,
            isshowRenew: false,
            renewDate: '',
            renewId: -1,
            currentPage: 1,
            total: 0,
            ep_id: '',
            areaUserId: '',
            areaOptions: [],
            epOptions: [],
            keyword: '',
            dateType: '',
            startDate: '',
            endDate: '',
            sortBy: '',
            shopUserId: '',
            parId: '1',
            parOptions: [{
                value: '0',
                label: '不参与数据统计'
            },{
                value: '1',
                label: '参与数据统计'
            }],
            sortOptions: [{
                value: 'user_count',
                label: '子账号'
            },{
                value: 'customer_count',
                label: '客户'
            },{
                value: 'customer_record_count',
                label: '成交单量'
            },{
                value: 'browsing_records_count',
                label: '曝光'
            },{
                value: 'comment_count',
                label: '口碑积攒'
            },{
                value: 'vr_imgs_count',
                label: '全景设计'
            }],
            isAllocation: false,
            title: '',
            userOptions: [],
            userId: '',
            shopId: 0,
            btnLoading: false,
            authOptions: [{
                value: 1,
                label: '门店认证未提交'
            },{
                value: 2,
                label: '门店认证待审核'
            },{
                value: 3,
                label: '门店认证审核通过'
            },{
                value: 4,
                label: '门店认证审核不通过'
            },{
                value: 5,
                label: '支付审核未提交'
            },{
                value: 6,
                label: '支付审核待审核'
            },{
                value: 7,
                label: '支付审核审核通过'
            },{
                value: 8,
                label: '支付审核审核不通过'
            }],
            authStatus: '',
            isCreateStore: false,
            typeOptions: [{
                label: '直营',
                value: '直营'
            },{
                label: '分销',
                value: '分销'
            }],
            option: {
                disabledDate:(time)=> {
                    return time.getTime() < Date.now()-1 * 24 * 3600 * 1000
                }
            },
            renewName: '',
            is_need_pay: false,
            pay_real_price: '',
            pay_way: '总部转账',
            wayOptions: [{
                value: '总部转账',
                label: '总部转账'
            },{
                value: '微信支付',
                label: '微信支付'
            }]
        }
    },
    mounted () {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.getShopList();
        this.getUserConditions();
        this.getEpConditions();
    },
    watch: {
        isshowRenew(newVal,oldVal){
            if(!newVal){
                this.renewDate = '';
                this.renewId = -1;
                this.is_need_pay = false;
                this.pay_way = '总部转账'
            }
        }
    },
    methods: {
        // 导出
        submitExport(){
            let that = this;
            let token = localStorage.getItem("token");
            var authorization = 'Bearer ' + token
            let params = {
                ep_id: this.ep_id,
                keyword: this.keyword,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                sort_by: this.sortBy,
                user_id: this.shopUserId,
                auth_status: this.authStatus,
                sale_id: this.areaUserId,
                is_statistic: this.parId
            }
            this.btnLoading = true;
            let url = common.apiServer+'/customerservicev1/enterprise_brand/export'
            axios({
                url: url,
                responseType: "blob",
                method: 'POST',
                data: params,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization,
                }
            }).then(function(res) {
                const blob = new Blob([res.data]);
                const fileName = '门店数据导出.xls';
                const linkNode = document.createElement('a');
                linkNode.download = fileName; //a标签的download属性规定下载文件的名称
                linkNode.style.display = 'none';
                linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
                document.body.appendChild(linkNode);
                linkNode.click();  //模拟在按钮上的一次鼠标单击
                URL.revokeObjectURL(linkNode.href); // 释放URL 对象
                document.body.removeChild(linkNode);
                if(res.status == 200){
                    that.btnLoading = false;
                }
            })
        },
        // 导出
        submitExport2(){
            let that = this;
            let token = localStorage.getItem("token");
            var authorization = 'Bearer ' + token
            let params = {
                ep_id: this.ep_id,
                keyword: this.keyword,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                sort_by: this.sortBy,
                user_id: this.shopUserId,
                auth_status: this.authStatus,
                sale_id: this.areaUserId,
                is_statistic: this.parId
            }
            this.btnLoading = true;
            let url = common.apiServer+'/customerservicev1/enterprise_brand/export2'
            axios({
                url: url,
                responseType: "blob",
                method: 'POST',
                data: params,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization,
                }
            }).then(function(res) {
                const blob = new Blob([res.data]);
                const fileName = '门店数据导出.xls';
                const linkNode = document.createElement('a');
                linkNode.download = fileName; //a标签的download属性规定下载文件的名称
                linkNode.style.display = 'none';
                linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
                document.body.appendChild(linkNode);
                linkNode.click();  //模拟在按钮上的一次鼠标单击
                URL.revokeObjectURL(linkNode.href); // 释放URL 对象
                document.body.removeChild(linkNode);
                if(res.status == 200){
                    that.btnLoading = false;
                }
            })
        },
        // 是否参与统计
        clickPart(row){
            let state = row.is_statistic==1?'不参与数据统计':'参与数据统计'
            this.$confirm(row.name+'--设置为'+state+', 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect("/customerservicev1/shop/setStatistic",{id: row.id},(res)=>{
                    if(res.isSuccess == 1){
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.getShopList();
                    }else{
                       this.$message({
                            message: res.msg,
                            type: 'warning'
                        }); 
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        //同步产品
        clickSyn(row){
            this.$confirm('是否确定同步产品?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    shop_id: row.id,
                    ep_id: row.ep_id,
                }
                common.connect("/customerservicev1/shop/synShopGoods",params,(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 删除
        clickDel(row){  
            this.$confirm('此操作将永久删除--'+row.name+', 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect("/customerservicev1/shop/delete",{id: row.id},(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getShopList();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 续费
        submitRenew(){
            if(this.renewDate == ''){
                this.$message.error('请选择续费到期日期！');
                return;
            }
            if(this.is_need_pay && this.pay_real_price == ''){
                this.$message.error('请输入订单价格！');
                return;
            }
            if(this.is_need_pay && this.pay_way == ''){
                this.$message.error('请选择支付方式！');
                return;
            }
            let params = {
                id: this.renewId,
                contract_end_time: timestampToTime(this.renewDate),
                is_need_pay: this.is_need_pay?1:0,
            }
            if(this.is_need_pay){
                params.pay_real_price = this.pay_real_price;
                params.pay_way = this.pay_way
            }
            this.btnLoading = true;
            common.connect("/customerservicev1/shop/renew",params,(res)=>{
                if(res.isSuccess == 1){
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getShopList();
                    this.btnLoading = false;
                    this.isshowRenew = false;
                }
            });
        },
        clickRenew(row){
            this.pay_real_price = row.enterprise_brand.unit_price_dealer / 100
            this.renewId = row.id;
            this.renewDate = row.contract_end_time;
            this.renewName = row.name;
            this.isshowRenew = true;
        },
        handleCloseRenew(){
            this.isshowRenew = false;
            this.renewId = -1
        },
        // 判断
        tableRowClassName({row, rowIndex}){
             if(row.shop_status === 2){
                return 'warning-row';
            }else if(row.shop_status === 3){
                return 'danger-row';
            }else if(row.shop_status === 4){
                return 'info-row';
            }
        },
        // 关闭创建门店弹窗
        handleCloseStore(){
            this.isCreateStore = false
        },
        // 跳转详情
        clickInfo(row){
            localStorage.removeItem('shop')
            let newPage = this.$router.resolve({ 
                path: 'shopDataInfo', 
            });
            window.open(newPage.href, '_blank');
            localStorage.setItem('shop',JSON.stringify(row));
            localStorage.setItem('jump',3);
        },
        // 确定分配
        submitAllocation(){
            let params = {
                shop_id: this.shopId,
                user_id: this.userId
            }
            this.btnLoading = true;
            common.connect("/customerservicev1/shop/allocation",params,(res)=>{
                if(res.isSuccess == 1){
                    this.getShopList();
                    this.isAllocation = false;
                    this.userId = '';
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.btnLoading = false;
                }
            });
        },
        // 分配弹窗
        clickAllocation(row){
            this.isAllocation = true;
            this.title = row.name;
            this.shopId = row.id;
        },
        handleClose(){
            this.isAllocation = false;
            this.userId = '';
        },
        // 日期选择
        changeDate(startDate,endDate){
          this.currentPage = 1;
          this.startDate = startDate.slice(0,11)+' 00:00:01';
          this.endDate = endDate.slice(0,11)+' 23:59:59';
          this.getShopList()
        },
        clickTagDate(item){
          this.dateType = item.label;
          this.currentPage = 1;
          if(item.label != 'date'){
            this.startDate = '';
            this.endDate = '';
            this.getShopList();
          }
        },
        // 参与统计
        parChange(){
            this.currentPage = 1;
            this.getShopList();
        },
        // 区域经理筛选
        selectAreaChange(){
            this.currentPage = 1;
            this.getShopList();
        },
        // 品牌筛选
        selectChangeEp(){
            this.currentPage = 1;
            this.getSaleManager();
        },
        // 点击排序
        changeShow(item){
            this.currentPage = 1;
            this.sortBy = item;
            this.getShopList();
        },
        selectChangeAuth(){
            this.currentPage = 1;
            this.getShopList();
        },
        // 用户筛选
        selectChange(){
            this.currentPage = 1;
            this.getShopList();
        },
        // 搜索
        clickSearch(){
            this.currentPage = 1;
            this.getShopList();
        },
        // 获取负责人数据
        getUserConditions(){
            common.connect("/customerservicev1/admin/userConditions",{},(res)=>{
                let resultSecond = res.data.map(v =>{
                    return{
                        value: v.user_id,
                        label: v.nickname
                    }
                })
                let quan = {
                    label: '暂无分配客服',
                    value: -1
                }
                resultSecond.unshift(quan);
                this.userOptions = resultSecond;
            });
        },
        // 获取品牌数据
        getEpConditions(){
            common.connect("/customerservicev1/enterprise_brand/EpConditions",{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.ep_id,
                    label: v.name
                }
                })
                this.epOptions = resultSecond;
            });
        },
        // 获取区域经理数据
        getSaleManager(){
            let params = {
                ep_id: this.ep_id
            }
            common.connect("/customerservicev1/shop/saleManagerConditions",params,(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.id,
                    label: v.name
                }
                })
                this.areaOptions = resultSecond;
                this.getShopList();
            });
        },
        // 获取列表数据
        getShopList(){
            this.loading = true;
            let params = {
                keyword: this.keyword,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                sort_by: this.sortBy,
                user_id: this.shopUserId,
                page: this.currentPage,
                auth_status: this.authStatus,
                ep_id: this.ep_id,
                sale_id: this.areaUserId,
                is_statistic: this.parId
            }
            common.connect("/customerservicev1/shop/index",params,(res)=>{
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getShopList();
        }
    },
}
</script>

<style lang='scss'>
.shopList{
    .sl-title{
        display: flex;
        //justify-content: space-between;
        align-items: center;
    }
    .box-wrap{
        background: #fff;
        padding-top: 15px;
    }
    .table-wrap{
        width: 100%;
        .vm-wrap{
            position: relative;
            .tag{
                position: absolute;
                top: 0;
                left: 0;
                background: #72a5fe;
                color: #fff;
                font-size: 6px !important;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: first baseline;
                -webkit-clip-path: polygon(40px 0px, 0px 0px, 0px 40px, 0px 40px);
                .new{
                    transform: rotate(-45deg);
                    margin-left: 2px;
                    margin-top: 2px;
                    font-size: 6px !important;
                }
            }
        }
    }
    .screen-wrap{
        .sel-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .sw-wrap{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .sw-item{
            display: flex;
            flex-wrap: wrap;
            .select-wrap{
                margin-right: 20px;
                padding-bottom: 15px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .title{
                    margin-right: 10px;
                }
            }
            .search-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-bottom: 15px;
                .el-input{
                    width: 200px;
                    margin-right: 10px;
                }
            }
        }
        .tag-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .tw-item{
                font-size: 14px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-left: 10px;
                .item{
                    margin-right: 5px;
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    background: #fff;
                }
            }
        }
    }
    .sort-wrap{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sort-item{
        background: #eee;
    }
    .operation-wrap{
        display: flex;
        justify-content: center;
        align-items: center;
        .iconfont{
            font-size: 16px;
            padding: 0 8px;
            cursor: pointer;
        }
        .el-icon-sort{
            transform: rotate(90deg);
        }
    }
    .pagination-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 5px;
    }
    .dialog-wrap{
        width: 500px;
        .title{
            display: flex;
            justify-content: center;
            font-size: 17px;
            color: #000;
            font-weight: bold;
            padding-top: 20px;
        }
        .renew{
            .item{
                .tit{
                    color: #333;
                    font-size: 15px;
                    padding: 10px 0;
                }
            }
            .el-input{
                width: 455px !important;
            }
        }
        .content{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: 20px;
            padding: 30px 0;
            .tag{
                margin-right: 10px;
            }
            .el-input{
                width: 390px;
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: flex-end;
            margin-right: 25px;
            padding-bottom: 10px;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .el-table tbody tr:hover>td {
        background-color: transparent !important;
    }
    .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf  {
        background-color: transparent !important;
    }
    .el-table__fixed-right tbody tr:hover>td {
        background-color: transparent !important;
    }
}
</style>
