<!--
 * @Descripttion: 门店列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-13 14:17:20
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-09-08 17:47:40
-->
<template>
    <div class="shopDataList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">门店数据</div>
        </div>
        <div class="case-wrap">
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="门店列表" name="first">
                    <shopList ref="shopList"></shopList>
                </el-tab-pane>
                <el-tab-pane label="门店跟进" name="second">
                    <shopFollowData ref="shopFollowData"></shopFollowData>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import shopList from './shopComponents/shopList'
import shopFollowData from './shopComponents/shopFollowData'
export default {
    components: {
      shopList,
      shopFollowData
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'shopDataList',
            activeName: 'first',
        }
    },
    mounted () {
        
    },
}
</script>

<style lang='scss'>
.shopDataList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px 20px 5px 20px;
        margin-top: 20px;
        border-radius: 6px;
    }
    
}
</style>
